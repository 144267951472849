:root {
  --coral: #ff4141;
  --warning: #ff9900;
  --dark-text: #292929;
  --light-text: #fff;
  --dark-background: #354658;
  --light-background: #fff;
  --white: #fff;
  --not-white: #f3f6f8;
  --cloudy-blue: #c9d2da;
  --steel: #707d8f;
  --pale-grey: #f0f4f7;
  --light-grey: #e8edf2;
  --bluey-grey: #9ba6b5;
  --light-blue-grey: #d8e0e8;
  --blue-hover: #037ff4;
  --deep-sky-blue: #0084ff;
  --ocre: #d48c00;
  --green: #00cc55;
  --highlight-alt: #27cc00;
  --blue: #3578e5;
  --azure: #0084ff;
  --azure_rgb: 0, 132, 255;
  --mui-error: #ff7c00;

  --bg-primary: #ededed;
  --text-primary: #292929;
  --text-alt: #a3a4aa;
  --bg-dark: #24252b;
  --bg-dark-light: #2929298f;
  --bg-dark-alt: #3e4150;
  --bg-med: #f4f6fa;
  --bg-med-dark: #c5c5c5;
  --bg-light: #f8f8f8;
  --highlight: #ff4900;
  --highlight-hover: #ff8467;
  --highlight-active: #db1900;
  --highlight-alt: #46efa1;
  --secondary-hover: #5f6171;
  --border-light: #e8edf2;
}

* {
  font-family: "Source Sans Pro", sans-serif;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body,
html,
#app {
  min-height: 100vh;
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  overflow-x: hidden;
}

blockquote {
  margin: 0;
}

input,
textarea {
  font: inherit;
}

ul {
  padding: 0;
  margin: 0;
}

a,
button {
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font: inherit;

  &:visited {
    color: inherit;
  }

  &:hover {
    color: inherit;
  }
}

a > button {
  &:hover {
    text-decoration: none;
  }
}

strong {
  font-weight: 500;
}

input:-webkit-autofill {
  transition: background-color 1000000s ease-in-out 0s;
  -webkit-text-fill-color: var(--dark-text);
}

.pagination {
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  li {
    padding: 4px 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      border: 1px solid #9ba6b5;
      border-radius: 4px;
      padding: 2px 10px;
    }
    &.previous,
    &.next {
      padding: 0 10px;
      display: none;
      align-items: center;
    }
    a {
      font-size: 16px;
      color: var(--dark-text);
      font-weight: 300;
      outline: none;
    }
  }
}
